<template>
  <product title="Amazfit 轻量保暖抓绒外套"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="99"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://shop18009901.m.youzan.com/wscgoods/detail/3nhvyulxl1nv9"
  ></product>
</template>

<script>
import { CDN_URL } from '@/config'
import Product from '../Product'

export default {
  name: 'zhuarong',
  components: {
    Product
  },
  data () {
    return {
      slogan: '告别静电 | 远离起球 | 防寒保暖 | 轻量舒适',
      colors: [
        '夜幕黑(男)',
        '方钠蓝',
        '松柏绿',
        '夜幕黑(女)',
        '海湾绿',
        '银杏黄'
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/zhuarong/swiper/1.png',
          CDN_URL + '/images/product/zhuarong/swiper/2.png',
          CDN_URL + '/images/product/zhuarong/swiper/3.png'
        ],
        [
          CDN_URL + '/images/product/zhuarong/swiper/4.png',
          CDN_URL + '/images/product/zhuarong/swiper/5.png',
          CDN_URL + '/images/product/zhuarong/swiper/6.png'
        ],
        [
          CDN_URL + '/images/product/zhuarong/swiper/7.png',
          CDN_URL + '/images/product/zhuarong/swiper/8.png',
          CDN_URL + '/images/product/zhuarong/swiper/9.png'
        ],
        [
          CDN_URL + '/images/product/zhuarong/swiper/10.png',
          CDN_URL + '/images/product/zhuarong/swiper/11.png',
          CDN_URL + '/images/product/zhuarong/swiper/12.png'
        ],
        [
          CDN_URL + '/images/product/zhuarong/swiper/13.png',
          CDN_URL + '/images/product/zhuarong/swiper/14.png',
          CDN_URL + '/images/product/zhuarong/swiper/15.png'
        ],
        [
          CDN_URL + '/images/product/zhuarong/swiper/16.png',
          CDN_URL + '/images/product/zhuarong/swiper/17.png',
          CDN_URL + '/images/product/zhuarong/swiper/18.png'
        ]
      ],
      relativedProducts: [
      ],
      detailImages: [
        CDN_URL + '/images/product/zhuarong/detail/01.jpg',
        CDN_URL + '/images/product/zhuarong/detail/02.jpg',
        CDN_URL + '/images/product/zhuarong/detail/03.jpg',
        CDN_URL + '/images/product/zhuarong/detail/04.jpg',
        CDN_URL + '/images/product/zhuarong/detail/05.jpg',
        CDN_URL + '/images/product/zhuarong/detail/06.jpg',
        CDN_URL + '/images/product/zhuarong/detail/07.jpg',
        CDN_URL + '/images/product/zhuarong/detail/08.jpg',
        CDN_URL + '/images/product/zhuarong/detail/09.jpg',
        CDN_URL + '/images/product/zhuarong/detail/10.jpg',
        CDN_URL + '/images/product/zhuarong/detail/11.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/zhuarong/detail/12.jpg'
      ]
    }
  },
  methods: {
  }
}
</script>

<style>
</style>
